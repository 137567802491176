import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import "../styles/404.scss";

export default function PageNotFound() {
  return (
    <>
      <Helmet title="Page Not Found | PDAX Remit API" />

      <div className="not-found">
        <div className="not-found__container">
          <h1 className="not-found__title">404</h1>
          <h2 className="not-found__sub-title">Page Not Found</h2>
          <p className="not-found__description">
            Sorry, we haven't found the page you are looking for.
          </p>
          <Link to="/" className="btn btn--primary">
            Back to Main Page
          </Link>
        </div>

        <div className="bg-pattern" />
      </div>
    </>
  );
}
